<template>
	<v-container style="background: #1e1e1e; min-height: 100vh" fluid>
		<v-app-bar prominent app :short="true" elevation="1" id="hdr" :dark="true">
			<v-row dense>
				<v-col>
					<v-row dense>
						<v-col>
							<h2 style="color: white; text-align: center">
								Announcement Settings
							</h2>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col>
							<h5 style="color: white; text-align: center">
								Settings to control how announcements are displayed
							</h5>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-app-bar>
		<v-list flat dark>
			<v-list-item-group>
				<v-list-item
					v-for="(item, idx) in items"
					:key="`${item.Title}-${item.visible}`"
					:class="SelectedItem == idx ? 'highlighted' : ''"
					@mouseover="SelectedItem = idx"
					@click="select(item)"
					:to="item.to"
					v-show="item.visible"
				>
					<v-list-item-avatar :color="SelectedItem == idx ? 'primary' : ''">
						<v-icon v-show="SelectedItem == idx" style="color: white">
							mdi-target
						</v-icon>
						<v-icon v-show="SelectedItem != idx" style="color: white">
							{{ item.icon }}
						</v-icon>
					</v-list-item-avatar>
					<v-list-item-content>
						<h2 class="my-1 mb-2">
							{{ item.Title }}
						</h2>
						<v-list-item-subtitle style="font-size: 1rem" v-if="item.subtitle">
							{{ item.subtitle }}
						</v-list-item-subtitle>
					</v-list-item-content>
					<v-list-item-icon>
						<v-icon v-if="item.SettingTitle == null">
							{{ item.rightIcon() }}
						</v-icon>
						<span v-else>
							<v-row align="center">
								<v-col>
									{{ item.SettingTitle() }}
								</v-col>
							</v-row>
						</span>
					</v-list-item-icon>
				</v-list-item>
			</v-list-item-group>
		</v-list>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			SelectedItem: 0,
			SelectedSpeed: 0,
			SelectedHideInterval: 0,
			speeds: [1, 2, 3,4,5],
			HideIntervals: [
				-1,
				1 * 60 * 1000,
				2 * 60 * 1000,
				5 * 60 * 1000,
				10 * 60 * 1000,
			],
			items: [
				{
					Title: 'Back',
					subtitle: null,
					icon: 'mdi-undo',
					visible: true,
					action: () => {
						this.$router.go(-1);
					},
					SettingTitle: () => {
						return '';
					},
				},
				{
					Title: 'Scroll Speed',
					subtitle: 'Speed at which the ticker moves',
					visible: true,
					action: () => {
						this.SelectedSpeed++;
						if (this.SelectedSpeed >= this.speeds.length)
							this.SelectedSpeed = 0;

						let x = {};
						Object.assign(x, this.$store.state.Announcements);
						x.ScrollSpeed = this.speeds[this.SelectedSpeed];

						this.$store.dispatch('set_announcement', x);
					},
					SettingTitle: () => {
						this.SelectedSpeed;
						switch(this.$store.state.Announcements.ScrollSpeed){
							case 4:
								return 'extra extra slow'
							case 5:
								return 'extra slow';
							case 1:
								return 'slow';
							case 2:
								return 'medium'
							case 3:
								return 'fast';
						}
					},
				},
				{
					Title: 'Toggle Display Timer',
					subtitle:
						'This will hide the annoucement bar after displaying all announcements for a set time',
					visible: true,
					action: () => {
						this.SelectedHideInterval++;
						if (this.SelectedHideInterval >= this.HideIntervals.length)
							this.SelectedHideInterval = 0;

						let x = {};
						Object.assign(x, this.$store.state.Announcements);
						x.ToggleShowHideInterval = this.HideIntervals[
							this.SelectedHideInterval
						];

						this.$store.dispatch('set_announcement', x);
					},
					SettingTitle: () => {
						let timeAsMinutes =
							this.$store.state.Announcements.ToggleShowHideInterval /
							(60 * 1000);
						return this.$store.state.Announcements.ToggleShowHideInterval == -1
							? `Always Show`
							: `Hide for ${timeAsMinutes} Minute${
									timeAsMinutes > 1 ? 's' : ''
							  }`;
					},
				},
			],
		};
	},
	mounted() {
		window.addEventListener('keydown', this.keyboardShortcuts);
		this.SelectedSpeed = this.speeds.findIndex(
			(x) => x == this.$store.state.Announcements.ScrollSpeed
		);
		this.SelectedHideInterval = this.HideIntervals.findIndex(
			(x) => x == this.$store.state.Announcements.ToggleShowHideInterval
		);
	},
	beforeDestroy() {
		window.removeEventListener('keydown', this.keyboardShortcuts);
	},
	methods: {
		select(item) {
			item.action();
		},
		/**
		 * Should be +1 or -1
		 */
		move(dir) {
			if (dir == 1) {
				if (this.SelectedItem == this.items.length - 1) this.SelectedItem = -1;
				this.SelectedItem++;
			} else {
				if (this.SelectedItem == 0) this.SelectedItem = this.items.length;
				this.SelectedItem--;
			}
			if (!this.items[this.SelectedItem].visible) this.move(dir);
		},
		keyboardShortcuts(key) {
			if (window.enable_debug.keystrokes) console.log(key.key, key.keyCode);
			switch (key.key) {
				case 'ArrowLeft':
				case 'ArrowUp':
					this.move(-1);
					break;
				case 'ArrowRight':
				case 'ArrowDown':
					this.move(1);
					break;
				case 'Enter':
					this.select(this.items[this.SelectedItem]);
					break;
				case 'Escape':
					this.$router.push('/settings');
					break;
			}
		},
	},
};
</script>

<style scoped>
.highlighted {
	background: #24598c;
}
</style>
